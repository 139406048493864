import React from 'react';
import jwt_decode from 'jwt-decode';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';

const getAccToken = JSON.parse(localStorage.getItem('accessToken', {}));
const decodeToken = getAccToken ? jwt_decode(getAccToken.accessToken) : null;

const Dashboard = () => {
	return (
		<PageWrapper title='Dashboard'>
			<PageLayoutHeader />
			<Page container='fluid'>
				<div className='row'>
					<div className='col-12'>
						<Alert
							icon='Verified'
							isLight
							color='success'
							rounded={2}
							borderWidth={0}
							className='shadow-3d-primary'>
							<AlertHeading tag='h2' className='h4'>
								Welcome{' '}
								{decodeToken &&
									decodeToken.details.hris_org_tree.current_person.person_name}
							</AlertHeading>
							<span>Simplicity your work by using our apps!</span>
						</Alert>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Dashboard;
